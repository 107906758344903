
import { defineComponent, reactive, toRefs } from 'vue'
import { email, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { resetPassword } from '@/expressway-api/auth.api'
import useSnackbar from '@/composables/useSnackbar'
import Spinner from '@/components/Spinner.vue'
import { useRouter } from 'vue-router'
import { AxiosError } from 'axios'

export default defineComponent({
  name: 'ForgotPassword',
  components: { Spinner },
  setup () {
    const { setSnackbar } = useSnackbar()
    const router = useRouter()

    const state = reactive({
      email: '',
      loadingResetPassword: false
    })
    const rules = {
      email: { required, email }
    }

    const resetPasswordCall = async () => {
      state.loadingResetPassword = true
      resetPassword(state.email)
        .then(() => {
          router.push({ name: 'ForgotPasswordSuccess' })
        })
        .catch((e: AxiosError) => {
          state.loadingResetPassword = false
          setSnackbar(e.message)
        })
    }

    return {
      v$: useVuelidate(rules, state),
      resetPasswordCall,
      ...toRefs(state)
    }
  }
})

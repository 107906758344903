
import Check from '@/components/vectors/Check.vue'
import RevealableInput from '@/components/RevealableInput.vue'
import { defineComponent, reactive, toRefs, ref } from 'vue'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { sameAs } from 'vuelidate/lib/validators'
import { passwordCustomValidation } from '@/helpers/system'
import Letter from '@/components/vectors/Letter.vue'
import { setPassword } from '@/expressway-api/auth.api'
import { useRouter } from 'vue-router'
import useSnackbar from '@/composables/useSnackbar'
import { AxiosError } from 'axios'
import Spinner from '@/components/Spinner.vue'

export default defineComponent({
  name: 'SetPassword',
  components: { Letter, RevealableInput, Spinner, Check },
  setup () {
    const route = useRouter()
    const { setSnackbar } = useSnackbar()
    const state = reactive({
      newPassword: '',
      confirmNewPassword: ''
    })
    const loading = ref(false)
    const passwordUpdated = ref(false)
    const rules = {
      newPassword: passwordCustomValidation,
      confirmNewPassword: {
        required,
        sameAsPassword: helpers.withMessage(
          'Passwords do not match',
          sameAs(() => state.newPassword)
        )
      }
    }

    const setPasswordCall = async () => {
      loading.value = true
      const hash = route.currentRoute.value.fullPath.substring(
        20,
        route.currentRoute.value.fullPath.length
      )

      setPassword(hash, state.confirmNewPassword)
        .then(() => {
          passwordUpdated.value = true
          setSnackbar('Password updated', 'success')
        })
        .catch((e: AxiosError) => {
          setSnackbar(e.message)
        }).finally(() => {
          loading.value = false
        })
    }

    const goToLogin = () => {
      route.push({ name: 'Login' })
    }

    return {
      v$: useVuelidate(rules, state),
      ...toRefs(state),
      setPasswordCall,
      loading,
      passwordUpdated,
      goToLogin
    }
  }
})

import { BreadCrumb } from '@/models/System'
import { Router } from 'vue-router'
import { helpers, required, minLength } from '@vuelidate/validators'

export const getRouteBreadCrumbs = (router: Router) => {
  const pathArray = router.currentRoute.value.path.split('/')
  pathArray.shift()
  const breadcrumbs: Array<BreadCrumb> =
    pathArray.reduce((breadcrumbArray: Array<BreadCrumb>, path: string, idx: number) => {
      breadcrumbArray.push({
        path: path,
        to: breadcrumbArray[idx - 1]
          ? `/${breadcrumbArray[idx - 1].path}/${path}`
          : `/${path}`,
        text: (router.currentRoute.value.matched[idx].meta.breadCrumb ||
          router.currentRoute.value.matched[idx].name ||
          path) as string
      })
      return breadcrumbArray
    }, [])
  return breadcrumbs
}

export const convertPascalCaseToSpace = (text: string) =>
  text
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase())

const customPasswordValidator = (value: unknown) => {
  if (typeof value === 'string') {
    // eslint-disable-next-line
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!()/"\-_%*,.{}·~:;#?&£^<>|¦`¬{}+=])[a-zA-Z\d@$!()/"\-_%*,.{}·~:;#?&£^<>|¦`¬{}+=]{8,}$/
      .test(value)
  } else {
    return false
  }
}
export const passwordCustomValidation = {
  required,
  customPasswordValidator: helpers.withMessage(
    'Password must contain at least one lowercase character, ' +
    'one uppercase character, one number and one special character',
    customPasswordValidator
  ),
  minLength: helpers.withMessage('Password must be at least 8 characters long.', minLength(8))
}

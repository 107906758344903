
import { defineComponent, ref } from 'vue'
import Modal from '@/components/Modal.vue'
import Spinner from '@/components/Spinner.vue'
import GenericError from '@/components/GenericError.vue'
import kenticoClient from '@/kentico-api/httpClient'
import { acceptTandCs } from '@/expressway-api/auth.api'
import { useRouter } from 'vue-router'
import useSessions from '@/composables/useSessions'

export default defineComponent({
  components: { Modal, Spinner, GenericError },
  setup () {
    const modalContent = ref()
    const loading = ref(true)
    const error = ref(false)
    const router = useRouter()
    const { logOut } = useSessions()
    const loadingAcceptCall = ref(false)

    kenticoClient
      .get('/account_creation_t_cs')
      .then(({ data }) => {
        modalContent.value = data
      })
      .catch(() => {
        error.value = true
      })
      .finally(() => {
        loading.value = false
      })

    const doAcceptTandCs = () => {
      loadingAcceptCall.value = true

      acceptTandCs().then(() => {
        router.push({ name: 'MyAccount' })
      }).catch(() => {
        error.value = true
      })
    }

    const closeTandCsModal = () => {
      logOut()
      router.push({ name: 'Login' })
    }

    return {
      modalContent,
      loading,
      error,
      doAcceptTandCs,
      closeTandCsModal,
      loadingAcceptCall
    }
  }
})


import { defineComponent } from 'vue'
import AlertIcon from '@/components/vectors/AlertIcon.vue'
import GenericError from '@/components/GenericError.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: { GenericError, AlertIcon },
  setup () {
    const goToLogin = () => {
      useRouter().push({ name: 'Login' })
    }
    return { goToLogin }
  }
})

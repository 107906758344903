
import { defineComponent, reactive, toRefs } from 'vue'
import useVuelidate from '@vuelidate/core'
import { email, required, helpers } from '@vuelidate/validators'
import ModalLink from '@/components/CMSModalLink.vue'
import InfoIcon from '@/components/vectors/Info.vue'
import { createAccount } from '@/expressway-api/auth.api'
import useSnackbar from '@/composables/useSnackbar'
import Spinner from '@/components/Spinner.vue'
import { useRouter } from 'vue-router'
import Input from '@/components/Input.vue'
import Checkbox from '@/components/Checkbox.vue'
import { AxiosError } from 'axios'
import { sendExponeaCustomerRegistered } from '@/helpers/exponeaHelper'

export default defineComponent({
  components: {
    Spinner,
    ModalLink,
    InfoIcon,
    'v-input': Input,
    'v-checkbox': Checkbox
  },
  setup () {
    const router = useRouter()
    const { setSnackbar } = useSnackbar()
    const state = reactive({
      email: '',
      marketing: false,
      loadingCreateAccount: false
    })

    const rules = {
      email: {
        required,
        email: helpers.withMessage('Please enter a valid Email Address', email)
      },
      marketing: {}
    }

    const createAccountCall = async () => {
      state.loadingCreateAccount = true
      createAccount(state.email, state.marketing)
        .then(() => {
          sendExponeaCustomerRegistered(state.email)
          router.push({ name: 'CreateAccountSuccess' })
        })
        .catch((e: AxiosError) => {
          state.loadingCreateAccount = false
          setSnackbar(e.message)
        })
    }

    return {
      v$: useVuelidate(rules, state),
      createAccountCall,
      ...toRefs(state)
    }
  }
})

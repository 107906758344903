
import { defineComponent, ref } from 'vue'
import Input from '@/components/Input.vue'
import Eye from '@/components/vectors/Eye.vue'
import EyeSlash from '@/components/vectors/EyeSlash.vue'

export default defineComponent({
  components: { Eye, EyeSlash, Input },
  setup () {
    return { showInput: ref(false) }
  }
})


import { defineComponent, PropType } from 'vue'
import Letter from '@/components/vectors/Letter.vue'

export default defineComponent({
  components: { Letter },
  props: {
    action: {
      type: String as PropType<'reset' | 'set'>
    }
  }
})

<template>
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.363281C4.36364 0.363281 1.25818 2.6251 0 5.81783C1.25818 9.01055 4.36364 11.2724 8 11.2724C11.6364 11.2724 14.7418 9.01055 16 5.81783C14.7418 2.6251 11.6364 0.363281 8 0.363281ZM8 9.45419C5.99273 9.45419 4.36364 7.8251 4.36364 5.81783C4.36364 3.81055 5.99273 2.18146 8 2.18146C10.0073 2.18146 11.6364 3.81055 11.6364 5.81783C11.6364 7.8251 10.0073 9.45419 8 9.45419ZM8 3.63601C6.79273 3.63601 5.81818 4.61055 5.81818 5.81783C5.81818 7.0251 6.79273 7.99964 8 7.99964C9.20727 7.99964 10.1818 7.0251 10.1818 5.81783C10.1818 4.61055 9.20727 3.63601 8 3.63601Z"
      fill="#434343"
    />
  </svg>
</template>

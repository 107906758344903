<template>
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8978 6.97804C16.0341 7.19486 16.0341 7.47054 15.8978 7.68736C15.7698 7.89069 12.7139 12.6659 8 12.6659C7.26747 12.6613 6.54003 12.5437 5.84338 12.3173L13.7099 4.45076C14.5431 5.19732 15.2783 6.04652 15.8978 6.97804ZM15.1372 0.194854C15.3974 0.455182 15.3974 0.877173 15.1372 1.1375L2.47078 13.8039C2.30429 13.9812 2.05444 14.0538 1.81887 13.9932C1.58331 13.9327 1.39941 13.7486 1.33903 13.513C1.27866 13.2774 1.35139 13.0276 1.5288 12.8612L3.34143 11.0486C2.0718 10.1281 0.975126 8.99014 0.102168 7.68736C-0.0340561 7.47054 -0.0340561 7.19486 0.102168 6.97804C0.230166 6.77471 3.2861 1.99948 8 1.99948C9.2192 2.01176 10.417 2.32089 11.4899 2.90013L14.1952 0.194854C14.4555 -0.0649513 14.8769 -0.0649513 15.1372 0.194854ZM5.33339 7.3327C5.33631 7.80611 5.46864 8.26971 5.71605 8.67334L9.3413 5.04875C8.93761 4.80094 8.47368 4.66859 8 4.66609C6.52727 4.66609 5.33339 5.85997 5.33339 7.3327Z"
      fill="#262626"
    />
  </svg>
</template>
